import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      const response = await fetch("https://api-to4.vongquayvip.vip/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
      console.log("API Response:", data); // Kiểm tra dữ liệu trả về từ API
  
      if (response.ok) {
        if (data.role === "admin") {
          toast.success("Đăng nhập thành công!");
          localStorage.setItem("isAdmin", true); // Lưu trạng thái đăng nhập
          navigate("/admin"); // Chuyển hướng đến trang quản lý
        } else {
          toast.success("Đăng nhập thành công!");
          navigate("/"); // Chuyển hướng đến trang người dùng
        }
      } else {
        toast.error(data.message || "Email hoặc mật khẩu không đúng");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error("Đã xảy ra lỗi khi đăng nhập.");
    }
  };
  
  const handleRegister = async () => {
    try {
      const response = await fetch("https://api-to4.vongquayvip.vip/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: registerEmail,
          password: registerPassword,
          role: "user", // Bạn có thể thay đổi role tùy theo nhu cầu
        }),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success("Đăng ký thành công!");
        setShowRegisterPopup(false); // Đóng popup sau khi đăng ký thành công
      } else {
        toast.error(data.message || "Đăng ký không thành công");
      }
    } catch (error) {
      console.error("Error registering:", error);
      toast.error("Đã xảy ra lỗi khi đăng ký.");
    }
  };

  return (
    <div className="login-page">
      <h2>Admin Login</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Đăng nhập</button>

      {/* Nút mở popup đăng ký */}
      <button className="nutdangky" onClick={() => setShowRegisterPopup(true)}>Đăng ký</button>

      {/* Popup Đăng ký */}
      {showRegisterPopup && (
        <div className="register-popup">
          <div className="popup-content">
            <h2>Đăng Ký Tài Khoản</h2>
            <input
              type="email"
              placeholder="Email"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={registerPassword}
              onChange={(e) => setRegisterPassword(e.target.value)}
            />
            <button onClick={handleRegister}>Đăng ký</button>
            <button onClick={() => setShowRegisterPopup(false)}>Đóng</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
